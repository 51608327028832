import * as React from "react"
import { Data, Override } from "framer"

// Initial app state
const appState = Data({
    impressions: 0,
    addon1: false,
    addon2: false,
    totalPrice: 0,
    dummy: 0, // Dummy state to force re-renders
})

function formatNumber(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

function calculateDiscountedPrice(impressions, addon1, addon2) {
    let costPerImpression = 0.012

    if (addon1) {
        costPerImpression += 0.005
    }

    if (addon2) {
        costPerImpression += 0.002
    }

    let totalCost = impressions * costPerImpression

    if (impressions > 75000000) {
        totalCost *= 0.7 // 30% discount
    } else if (impressions > 50000000) {
        totalCost *= 0.75 // 25% discount
    } else if (impressions > 30000000) {
        totalCost *= 0.8 // 20% discount
    } else if (impressions > 15000000) {
        totalCost *= 0.85 // 15% discount
    } else if (impressions > 10000000) {
        totalCost *= 0.9 // 10% discount
    } else if (impressions > 2000000) {
        totalCost *= 0.95 // 5% discount
    } // < 2.000.000 has no discount

    return Math.round(totalCost)
}

function updateTotalPrice() {
    appState.totalPrice = calculateDiscountedPrice(
        appState.impressions,
        appState.addon1,
        appState.addon2
    )
    appState.dummy += 1 // Update dummy state to force re-renders
}

// The PriceDisplay CodeComponent
export function PriceDisplay() {
    return (
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>
            {formatNumber(appState.totalPrice)} kr.
        </div>
    )
}

// Override for the first toggle
export function Toggle1Override(): Override {
    return {
        onChange: (checked) => {
            console.log("Toggle 1 changed:", checked)
            appState.addon1 = checked
            updateTotalPrice()
            console.log(
                "Total Price after Toggle 1 change:",
                appState.totalPrice
            )
        },
    }
}

// Override for the second toggle
export function Toggle2Override(): Override {
    return {
        onChange: (checked) => {
            console.log("Toggle 2 changed:", checked)
            appState.addon2 = checked
            updateTotalPrice()
            console.log(
                "Total Price after Toggle 2 change:",
                appState.totalPrice
            )
        },
    }
}

// Override for the impressions input
export function ImpressionsInputOverride(): Override {
    return {
        onValueChange: (value) => {
            console.log("Impressions input changed:", value)
            const impressions = parseInt(value.replace(/\./g, ""), 10) || 0
            appState.impressions = impressions
            updateTotalPrice()
            console.log(
                "Total Price after Impressions input change:",
                appState.totalPrice
            )
        },
    }
}
